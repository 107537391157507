import React from 'react';
import {Link} from "react-router-dom";
import {withRouter} from "react-router-dom";
import $ from 'jquery';

const navbar = (props) => {
    const links = [
        {
            name: "About the Brand",
            link: "/"
        },
        {
            name: "Gel Polish",
            link: "/products/gel-polish"
        },
        /*{
            name: "Sculpting Gel",
            link: "/products/sculpting-gel"
        },*/
        {
            name: "Where to Buy",
            link: "/contact-us"
        }
    ];

    console.log(props.location.pathname);

    return(
        <div className="row mx-0">
        <nav className="navbar navbar-expand-sm bg-navbar fixed-top mx-0 px-0">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <i className="fad fa-bars" style={{color: "#4a1818"}}/>
            </button>

            <div className="collapse navbar-collapse" id="collapsibleNavbar">
                {/*<div className="col-sm-2">
                    <img className="col-sm-12" src={LogoInv}/>
                </div>*/}
                <ul className="navbar-nav mx-auto">
                    {links.map( (item, index) => {
                        return(
                            <li className={"nav-item" + ((props.location.pathname === item.link) ? " nav-item-active" : "")} key={index}>
                                <Link to={item.link} className="nav-link d-none d-lg-block d-md-block">
                                    | {item.name} |
                                </Link>
                                <Link to={item.link} className="nav-link d-sm-none d-md-none" onClick={()=>{$('.navbar-collapse').collapse('hide');}}>{item.name}</Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </nav>
        </div>
    )
};

export default withRouter(navbar);