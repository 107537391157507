import React from 'react';


import Logo from './../resources/img/gc_logo_inv.png';


const home = (props) => {
    return (
        <div className="container-fluid pt-3 pb-5 px-0">
            <div className="row mx-0 mt-4">
                <div className="col-12 jumbotron header-home">
                    <div className="col-sm-12 text-center p-0">
                        <div className="row py-5">
                            <div className="col-sm-12">
                                <div className="col-sm-6 mx-auto">
                                    <img src={Logo} className="col-sm-12" alt="logo"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mx-0">
                <div className="col-sm-8 m-auto pt-5 pb-5">
                    <div className="row">
                        <div className="col-sm-12">
                            <p>
                                Gel Couture is a South African brand developed and designed for nail professionals
                                who are looking for a top quality safe, reliable range of gel products at an fair,
                                affordable price. Our brand was developed and tested under real salon conditions on
                                over 6000 clients over a period of 18 month. Only after we were 100% satisfied with
                                the outcome, we felt confident in introducing the product to the professional nail
                                market.</p>
                            <p>
                                Gel Couture range of products consists of soak off gel polish in 96 fashionable
                                shades, hard non-soak off gel in variety of natural shades and special effects gel
                                polish colours suitable for specialised nail art creations.</p>
                            <p style={{color: "#a69080", fontSize: '1.2rem'}}>Gel Couture Brand offers only the best
                                quality products and we support this claim
                                with a FULL MONEY BACK GUARANTEE - if you feel that the product did not match your
                                expectations or any of our quality claims, we will refund you in full, no questions
                                asked!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default home;