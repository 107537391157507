import React from 'react';

import Logo from './../../resources/img/gc_logo_inv.png';

import BottlePic from './../../resources/img/281.jpg';

const home = (props) => {
    return (
        <div className="container-fluid pt-3 pb-5 px-0">
            <div className="row mx-0 mt-4">
                <div className="jumbotron header-sculpting-gel">
                    <div className="col-sm-12 text-center p-0">
                        <div className="row py-5">
                            <div className="col-sm-12">
                                <div className="col-sm-6 mx-auto">
                                    <img src={Logo} className="col-sm-12" alt="Gel Couture Logo"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pb-5 mx-0">
                <div className="col-sm-12">
                    <h2>GEL COUTURE SCULPTING GEL</h2>
                    <div className="col-sm-8 mx-auto pt-3">
                        <p>Lorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem
                            IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem
                            IpsumLorem IpsumLorem IpsumLorem IpsumLorem Ipsum</p>
                    </div>
                </div>
            </div>
            <div className="row text-center mx-0">
                <div className="col-sm-4">
                    <div className="row mx-auto">
                        <div className="col-sm-9 m-auto">
                            <img src={BottlePic} className="col-sm-12" alt="Sculpting Gel Colour Gel Clear"/>
                        </div>
                    </div>
                    <div className="row mx-auto">
                        <div className="col-sm-9 m-auto">
                            <p className="SculptingGelItemText">Sculpting Gel Colour Clear 30 ml</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="row mx-auto">
                        <div className="col-sm-9 m-auto">
                            <img src={BottlePic} className="col-sm-12" alt="Sculpting Gel Colour Gel Pink"/>
                        </div>
                    </div>
                    <div className="row mx-auto">
                        <div className="col-sm-9 m-auto">
                            <p className="SculptingGelItemText">Sculpting Gel Colour Pink 30 ml</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="row mx-auto">
                        <div className="col-sm-9 m-auto">
                            <img src={BottlePic} className="col-sm-12" alt="Sculpting Gel Colour Gel White"/>
                        </div>
                    </div>
                    <div className="row mx-auto">
                        <div className="col-sm-9 m-auto">
                            <p className="SculptingGelItemText">Sculpting Gel Colour White 30 ml</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5 mx-0">
                <div className="col-sm-4">
                    <div className="row mx-auto">
                        <div className="col-sm-9 m-auto">
                            <img src={BottlePic} className="col-sm-12" alt="Sculpting Gel Colour Cover Clear"/>
                        </div>
                    </div>
                    <div className="row mx-auto">
                        <div className="col-sm-9 m-auto">
                            <p className="SculptingGelItemText">Sculpting Gel Colour Cover Pink 30 ml</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="row mx-auto">
                        <div className="col-sm-9 m-auto">
                            <img src={BottlePic} className="col-sm-12" alt="Sculpting Gel Colour Gel Normal"/>
                        </div>
                    </div>
                    <div className="row mx-auto">
                        <div className="col-sm-9 m-auto">
                            <p className="SculptingGelItemText">Sculpting Gel Colour Natural 30 ml</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="row mx-auto">
                        <div className="col-sm-9 m-auto">
                            <img src={BottlePic} className="col-sm-12" alt="Sculpting Gel Colour Gel Nude"/>
                        </div>
                    </div>
                    <div className="row mx-auto">
                        <div className="col-sm-9 m-auto">
                            <p className="SculptingGelItemText">Sculpting Gel Colour Cover Nude 30 ml</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5 mx-0">
                <div className="col-sm-4 mx-auto">
                    <div className="row mx-auto">
                        <div className="col-sm-9 m-auto">
                            <img src={BottlePic} className="col-sm-12" alt="Sculpting Gel Colour Gel Rose"/>
                        </div>
                    </div>
                    <div className="row mx-auto">
                        <div className="col-sm-9 m-auto">
                            <p className="SculptingGelItemText">Sculpting Gel Colour Cover Rose 30 ml</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default home;