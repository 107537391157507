import React from 'react';
import GoToTopButton from "../components/ToTopButton/GoToTopButton";
import Logo from './../resources/img/gc_logo_inv.png';

const products = (props) => {
    const _resellers = [
        {
            region: "Gauteng",
            resellers: [
                {
                    name: "Nelé Naturale Pty Ltd",
                    email: "orders@nele.co.za",
                    phone: {tel: "+27129912146", cell: "+27746014249"},
                    address: ["1006 Olympus drive,", "Faerie Glen, Pretoria 0043"],
                },
                {
                    name: "Exclusive Beauty Solutions",
                    email: "orders@exclusivebeauty.co.za",
                    phone: {tel: "+27117914027",},
                    address: ["Unit 9, Boskruin Business Park,", "Bosbok Road, Randpark Ridge 2169"],
                },
                {
                    name: "Embellish Hair and Beauty",
                    email: "eastrand@nele.co.za",
                    address: ["29 Titaan Road,", "Wilropark, Roodepoort 1709"],
                    phone: {cell: "+27832841534", tel: '-'}
                },
                {
                    name: "Beyoutiful Nail Salon and Training Center ",
                    email: "roodepoort@nele.co.za",
                    address: ["19A Kloof Road,", "Bedfordview 2007"],
                    phone: {cell: "+27722552543", tel: '-'}
                },
            ],
        },
        {
            region: "Kwazulu Natal",
            resellers: [
                {
                    name: "Exclusive Beauty Solutions",
                    email: "kzn@exclusivebeauty.co.za",
                    phone: {cell: "+27664327784", tel: "-"},
                    address: ["-", "-"],
                }
            ],
        },
        {
            region: "Western Cape",
            resellers: [
                {
                    name: "Exclusive Beauty Solutions",
                    email: "ordersct@exclusivebeauty.co.za",
                    phone: {tel: "+27212027809", cell: "-"},
                    address: ["Unit 4, Block 1", "Northgate Island, Paardeneiland, Milnerton"],
                }
            ],
        },
        {
            region: "Free State",
            resellers: [
                {
                    name: "Explore Your Beauty",
                    email: "rozelmavn@gmail.com",
                    phone: {cell: "+27714531903", tel: "-"},
                    address: ["22 Dias Crescent,", "Dan Pienaar, Bloemfontein"],
                }
            ],
        },
    ];
    return (
        <div className="container-fluid pt-3 px-0 mt-5">
            <div className="row col-sm-12 py-5 mb-4 mx-0" style={{backgroundColor: '#4a1818'}}>
                <img src={Logo} alt="Logo" className="col-sm-3 mx-auto" height="100%"/>
            </div>
            <div className="row col-sm-12 mx-0">
                <h1 className="mx-auto">Where to buy</h1>
            </div>
            <div className="row col-sm-12 mx-0">
                <p className="mx-auto">Gel Couture Pty Ltd is a manufacturer of Gel Couture brand with the Head Office
                    situated in
                    Pretoria.
                    <br/>Please contact us with regards to marketing, commercial projects, distribution rights and other
                    inquiries related to the brand:<br/><br/>
                    <a href="mailto:info@gelcouture.co.za">info@gelcouture.co.za</a><br/>
                    Cell: +27824655862<br/><br/>
                    Gel Couture products are distributed in South Africa and Namibia through a wide network of appointed
                    stockists:
                </p>
            </div>
            <div className="row col-sm-12 pt-3 pb-3 mx-0">
                <h4 className="mx-auto">{_resellers[0].region}</h4>
            </div>
            <div className="row mx-0">
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor: '#a69080'}}>
                            {_resellers[0].resellers[0].name}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p><i className="fad fa-map-pin"
                                          style={{color: '#a69080'}}/> {_resellers[0].resellers[0].address[0]}<br/>{_resellers[0].resellers[0].address[1]}
                                    </p>
                                    <p>{(_resellers[0].resellers[0].phone.cell != null) ? 'Cell: ' + _resellers[0].resellers[0].phone.cell : ''}</p>
                                    <p>{(_resellers[0].resellers[0].phone.tel != null) ? 'Tel: ' + _resellers[0].resellers[0].phone.tel : ''}</p>
                                    <p><i className="fad fa-envelope" style={{color: '#a69080'}}/> Email: <a
                                        href={'mailto:' + _resellers[0].resellers[0].email}>{_resellers[0].resellers[0].email}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor: '#a69080'}}>
                            {_resellers[0].resellers[1].name}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p><i className="fad fa-map-pin"
                                          style={{color: '#a69080'}}/> {_resellers[0].resellers[1].address[0]}<br/>{_resellers[0].resellers[1].address[1]}
                                    </p>
                                    <p>Tel: {_resellers[0].resellers[1].phone.tel}</p>
                                    <p><br/></p>
                                    <p><i className="fad fa-envelope" style={{color: '#a69080'}}/> Email: <a
                                        href={'mailto:' + _resellers[0].resellers[1].email}>{_resellers[0].resellers[1].email}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor: '#a69080'}}>
                            {_resellers[0].resellers[2].name}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p><i className="fad fa-map-pin"
                                          style={{color: '#a69080'}}/> {_resellers[0].resellers[2].address[0]}<br/>{_resellers[0].resellers[2].address[1]}
                                    </p>
                                    <p>{(_resellers[0].resellers[2].phone.cell != null) ? 'Cell: ' + _resellers[0].resellers[2].phone.cell : "\n"}</p>
                                    <p><br/></p>
                                    <p><i className="fad fa-envelope" style={{color: '#a69080'}}/> Email: <a
                                        href={'mailto:' + _resellers[0].resellers[2].email}>{_resellers[0].resellers[2].email}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor: '#a69080'}}>
                            {_resellers[0].resellers[3].name}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p><i className="fad fa-map-pin"
                                          style={{color: '#a69080'}}/> {_resellers[0].resellers[3].address[0]}<br/>{_resellers[0].resellers[3].address[1]}
                                    </p>
                                    <p>{(_resellers[0].resellers[3].phone.cell != null) ? 'Cell: ' + _resellers[0].resellers[3].phone.cell : ''}</p>
                                    <p><br/></p>
                                    <p><i className="fad fa-envelope" style={{color: '#a69080'}}/> Email: <a
                                        href={'mailto:' + _resellers[0].resellers[3].email}>{_resellers[0].resellers[3].email}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row col-sm-12 pt-5 pb-3 mx-0">
                <h4 className="mx-auto">{_resellers[1].region}</h4>
            </div>
            <div className="row mx-0">
                <div className="col-sm-12 col-md-6 col-lg-3 mx-auto">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor: '#a69080'}}>
                            {_resellers[1].resellers[0].name}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p>Tanya Pelser<br/></p>
                                    <p>{(_resellers[1].resellers[0].phone.cell != null) ? 'Cell: ' + _resellers[1].resellers[0].phone.cell : ''}</p>
                                    <p><br/></p>
                                    <p><i className="fad fa-envelope" style={{color: '#a69080'}}/> Email: <a
                                        href={'mailto:' + _resellers[1].resellers[0].email}>{_resellers[1].resellers[0].email}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row col-sm-12 pt-5 pb-3 mx-0">
                <h4 className="mx-auto">{_resellers[2].region}</h4>
            </div>
            <div className="row mx-0">
                <div className="col-sm-12 col-md-6 col-lg-3 mx-auto">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor: '#a69080'}}>
                            {_resellers[2].resellers[0].name}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p><i className="fad fa-map-pin"
                                          style={{color: '#a69080'}}/> {_resellers[2].resellers[0].address[0]}<br/>{_resellers[2].resellers[0].address[1]}
                                    </p>
                                    <p><br/></p>
                                    <p>{(_resellers[2].resellers[0].phone.tel != null) ? 'Tel: ' + _resellers[2].resellers[0].phone.tel : ''}</p>
                                    <p><i className="fad fa-envelope" style={{color: '#a69080'}}/> Email: <a
                                        href={'mailto:' + _resellers[2].resellers[0].email}>{_resellers[2].resellers[0].email}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row col-sm-12 pt-5 pb-3 mx-0">
                <h4 className="mx-auto">{_resellers[3].region}</h4>
            </div>
            <div className="row mx-0">
                <div className="col-sm-12 col-md-6 col-lg-3 mx-auto">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor: '#a69080'}}>
                            {_resellers[3].resellers[0].name}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p><i className="fad fa-map-pin"
                                          style={{color: '#a69080'}}/> {_resellers[3].resellers[0].address[0]}<br/>{_resellers[3].resellers[0].address[1]}
                                    </p>
                                    <p>{(_resellers[3].resellers[0].phone.cell != null) ? 'Cell: ' + _resellers[3].resellers[0].phone.cell : ''}</p>
                                    <p><br/></p>
                                    <p><i className="fad fa-envelope" style={{color: '#a69080'}}/> Email: <a
                                        href={'mailto:' + _resellers[3].resellers[0].email}>{_resellers[3].resellers[0].email}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-5"/>
            <GoToTopButton/>
        </div>
    )
};

export default products;