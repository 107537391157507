import React from 'react';

import Logo from './../../resources/img/gc_logo_inv.png';

import VelvetTopCoatImg from './../../resources/img/color-gel/velvet-top-coat.jpg';
import DiamondTopCoatImg from './../../resources/img/color-gel/diamond-top-coat.JPG';
import GelPolishImg from './../../resources/img/color-gel/gel-polish.jpg';
import RubberBaseImg from './../../resources/img/color-gel/rubber-base.jpg';
import ToTopButton from './../../components/ToTopButton/GoToTopButton';

//ColorChart:
import ColorGel1001 from './../../resources/img/colors/color-gels/1001.jpg';
import ColorGel1002 from './../../resources/img/colors/color-gels/1002.jpg';
import ColorGel1003 from './../../resources/img/colors/color-gels/1003.jpg';
import ColorGel1004 from './../../resources/img/colors/color-gels/1004.jpg';
import ColorGel1005 from './../../resources/img/colors/color-gels/1005.jpg';
import ColorGel1006 from './../../resources/img/colors/color-gels/1006.jpg';
import ColorGel1007 from './../../resources/img/colors/color-gels/1007.jpg';
import ColorGel1008 from './../../resources/img/colors/color-gels/1008.jpg';
import ColorGel1009 from './../../resources/img/colors/color-gels/1009.jpg';
import ColorGel1010 from './../../resources/img/colors/color-gels/1010.jpg';
import ColorGel1011 from './../../resources/img/colors/color-gels/1011.jpg';
import ColorGel1012 from './../../resources/img/colors/color-gels/1012.jpg';
import ColorGel1013 from './../../resources/img/colors/color-gels/1013.jpg';
import ColorGel1014 from './../../resources/img/colors/color-gels/1014.jpg';
import ColorGel1015 from './../../resources/img/colors/color-gels/1015.jpg';
import ColorGel1016 from './../../resources/img/colors/color-gels/1016.jpg';
import ColorGel1017 from './../../resources/img/colors/color-gels/1017.jpg';
import ColorGel1018 from './../../resources/img/colors/color-gels/1018.jpg';
import ColorGel1019 from './../../resources/img/colors/color-gels/1019.jpg';
import ColorGel1020 from './../../resources/img/colors/color-gels/1020.jpg';
import ColorGel1021 from './../../resources/img/colors/color-gels/1021.jpg';
import ColorGel1022 from './../../resources/img/colors/color-gels/1022.jpg';
import ColorGel1023 from './../../resources/img/colors/color-gels/1023.jpg';
import ColorGel1024 from './../../resources/img/colors/color-gels/1024.jpg';
import ColorGel1025 from './../../resources/img/colors/color-gels/1025.jpg';
import ColorGel1026 from './../../resources/img/colors/color-gels/1026.jpg';
import ColorGel1027 from './../../resources/img/colors/color-gels/1027.jpg';
import ColorGel1028 from './../../resources/img/colors/color-gels/1028.jpg';
import ColorGel1029 from './../../resources/img/colors/color-gels/1029.jpg';
import ColorGel1030 from './../../resources/img/colors/color-gels/1030.jpg';
import ColorGel1031 from './../../resources/img/colors/color-gels/1031.jpg';
import ColorGel1032 from './../../resources/img/colors/color-gels/1032.jpg';
import ColorGel1033 from './../../resources/img/colors/color-gels/1033.jpg';
import ColorGel1034 from './../../resources/img/colors/color-gels/1034.jpg';
import ColorGel1035 from './../../resources/img/colors/color-gels/1035.jpg';
import ColorGel1036 from './../../resources/img/colors/color-gels/1036.jpg';
import ColorGel1037 from './../../resources/img/colors/color-gels/1037.jpg';
import ColorGel1038 from './../../resources/img/colors/color-gels/1038.jpg';
import ColorGel1039 from './../../resources/img/colors/color-gels/1039.jpg';
import ColorGel1040 from './../../resources/img/colors/color-gels/1040.jpg';
import ColorGel1041 from './../../resources/img/colors/color-gels/1041.jpg';
import ColorGel1042 from './../../resources/img/colors/color-gels/1042.jpg';
import ColorGel1043 from './../../resources/img/colors/color-gels/1043.jpg';
import ColorGel1044 from './../../resources/img/colors/color-gels/1044.jpg';
import ColorGel1045 from './../../resources/img/colors/color-gels/1045.jpg';
import ColorGel1046 from './../../resources/img/colors/color-gels/1046.jpg';
import ColorGel1047 from './../../resources/img/colors/color-gels/1047.jpg';
import ColorGel1048 from './../../resources/img/colors/color-gels/1048.jpg';
import ColorGel1049 from './../../resources/img/colors/color-gels/1049.jpg';
import ColorGel1050 from './../../resources/img/colors/color-gels/1050.jpg';
import ColorGel1051 from './../../resources/img/colors/color-gels/1051.jpg';
import ColorGel1052 from './../../resources/img/colors/color-gels/1052.jpg';
import ColorGel1053 from './../../resources/img/colors/color-gels/1053.jpg';
import ColorGel1054 from './../../resources/img/colors/color-gels/1054.jpg';
import ColorGel1055 from './../../resources/img/colors/color-gels/1055.jpg';
import ColorGel1056 from './../../resources/img/colors/color-gels/1056.jpg';
import ColorGel1057 from './../../resources/img/colors/color-gels/1057.jpg';
import ColorGel1058 from './../../resources/img/colors/color-gels/1058.jpg';
import ColorGel1059 from './../../resources/img/colors/color-gels/1059.jpg';
import ColorGel1060 from './../../resources/img/colors/color-gels/1060.jpg';
import ColorGel1061 from './../../resources/img/colors/color-gels/1061.jpg';
import ColorGel1062 from './../../resources/img/colors/color-gels/1062.jpg';
import ColorGel1063 from './../../resources/img/colors/color-gels/1063.jpg';
import ColorGel1064 from './../../resources/img/colors/color-gels/1064.jpg';
import ColorGel1065 from './../../resources/img/colors/color-gels/1065.jpg';
import ColorGel1066 from './../../resources/img/colors/color-gels/1066.jpg';
import ColorGel1067 from './../../resources/img/colors/color-gels/1067.jpg';
import ColorGel1068 from './../../resources/img/colors/color-gels/1068.jpg';
import ColorGel1069 from './../../resources/img/colors/color-gels/1069.jpg';
import ColorGel1070 from './../../resources/img/colors/color-gels/1070.jpg';
import ColorGel1071 from './../../resources/img/colors/color-gels/1071.jpg';
import ColorGel1072 from './../../resources/img/colors/color-gels/1072.jpg';
import ColorGel1073 from './../../resources/img/colors/color-gels/1073.jpg';
import ColorGel1074 from './../../resources/img/colors/color-gels/1074.jpg';
import ColorGel1075 from './../../resources/img/colors/color-gels/1075.jpg';
import ColorGel1076 from './../../resources/img/colors/color-gels/1076.jpg';
import ColorGel1077 from './../../resources/img/colors/color-gels/1077.jpg';
import ColorGel1078 from './../../resources/img/colors/color-gels/1078.jpg';
import ColorGel1079 from './../../resources/img/colors/color-gels/1079.jpg';
import ColorGel1080 from './../../resources/img/colors/color-gels/1080.jpg';
import ColorGel1081 from './../../resources/img/colors/color-gels/1081.jpg';
import ColorGel1082 from './../../resources/img/colors/color-gels/1082.jpg';
import ColorGel1083 from './../../resources/img/colors/color-gels/1083.jpg';
import ColorGel1084 from './../../resources/img/colors/color-gels/1084.jpg';
import ColorGel1085 from './../../resources/img/colors/color-gels/1085.jpg';
import ColorGel1086 from './../../resources/img/colors/color-gels/1086.jpg';
import ColorGel1087 from './../../resources/img/colors/color-gels/1087.jpg';
import ColorGel1088 from './../../resources/img/colors/color-gels/1088.jpg';
import ColorGel1089 from './../../resources/img/colors/color-gels/1089.jpg';
import ColorGel1090 from './../../resources/img/colors/color-gels/1090.jpg';
import ColorGel1091 from './../../resources/img/colors/color-gels/1091.jpg';
import ColorGel1092 from './../../resources/img/colors/color-gels/1092.jpg';
import ColorGel1093 from './../../resources/img/colors/color-gels/1093.jpg';
import ColorGel1094 from './../../resources/img/colors/color-gels/1094.jpg';
import ColorGel1095 from './../../resources/img/colors/color-gels/1095.jpg';
import ColorGel1096 from './../../resources/img/colors/color-gels/1096.jpg';


import colorChartPDF from './../../resources/files/GelCouture-GelPolish-ColourChart.pdf';

const colorChart = [
    {
        name: "1001",
        src: ColorGel1001,
        category: "cremé",
    },
    {
        name: "1002",
        src: ColorGel1002,
        category: "sheer",
    },
    {
        name: "1003",
        src: ColorGel1003,
        category: "cremé",
    },
    {
        name: "1004",
        src: ColorGel1004,
        category: "cremé",
    },
    {
        name: "1005",
        src: ColorGel1005,
        category: "cremé",
    },
    {
        name: "1006",
        src: ColorGel1006,
        category: "cremé",
    },
    {
        name: "1007",
        src: ColorGel1007,
        category: "cremé",
    },
    {
        name: "1008",
        src: ColorGel1008,
        category: "cremé",
    },
    {
        name: "1009",
        src: ColorGel1009,
        category: "cremé",
    },
    {
        name: "1010",
        src: ColorGel1010,
        category: "shimmer",
    },
    {
        name: "1011",
        src: ColorGel1011,
        category: "cremé",
    },
    {
        name: "1012",
        src: ColorGel1012,
        category: "metallic",
    },
    {
        name: "1013",
        src: ColorGel1013,
        category: "cremé",
    },
    {
        name: "1014",
        src: ColorGel1014,
        category: "cremé",
    },
    {
        name: "1015",
        src: ColorGel1015,
        category: "cremé",
    },
    {
        name: "1016",
        src: ColorGel1016,
        category: "cremé",
    },
    {
        name: "1017",
        src: ColorGel1017,
        category: "cremé",
    },
    {
        name: "1018",
        src: ColorGel1018,
        category: "cremé",
    },
    {
        name: "1019",
        src: ColorGel1019,
        category: "cremé",
    },
    {
        name: "1020",
        src: ColorGel1020,
        category: "cremé",
    },
    {
        name: "1021",
        src: ColorGel1021,
        category: "cremé",
    },
    {
        name: "1022",
        src: ColorGel1022,
        category: "cremé",
    },
    {
        name: "1023",
        src: ColorGel1023,
        category: "cremé",
    },
    {
        name: "1024",
        src: ColorGel1024,
        category: "cremé",
    },
    {
        name: "1025",
        src: ColorGel1025,
        category: "cremé",
    },
    {
        name: "1026",
        src: ColorGel1026,
        category: "cremé",
    },
    {
        name: "1027",
        src: ColorGel1027,
        category: "cremé",
    },
    {
        name: "1028",
        src: ColorGel1028,
        category: "cremé",
    },
    {
        name: "1029",
        src: ColorGel1029,
        category: "cremé",
    },
    {
        name: "1030",
        src: ColorGel1030,
        category: "cremé",
    },
    {
        name: "1031",
        src: ColorGel1031,
        category: "cremé",
    },
    {
        name: "1032",
        src: ColorGel1032,
        category: "cremé",
    },
    {
        name: "1033",
        src: ColorGel1033,
        category: "cremé",
    },
    {
        name: "1034",
        src: ColorGel1034,
        category: "cremé",
    },
    {
        name: "1035",
        src: ColorGel1035,
        category: "cremé",
    },
    {
        name: "1036",
        src: ColorGel1036,
        category: "cremé",
    },
    {
        name: "1037",
        src: ColorGel1037,
        category: "cremé",
    },
    {
        name: "1038",
        src: ColorGel1038,
        category: "cremé",
    },
    {
        name: "1039",
        src: ColorGel1039,
        category: "cremé",
    },
    {
        name: "1040",
        src: ColorGel1040,
        category: "cremé",
    },
    {
        name: "1041",
        src: ColorGel1041,
        category: "cremé",
    },
    {
        name: "1042",
        src: ColorGel1042,
        category: "cremé",
    },
    {
        name: "1043",
        src: ColorGel1043,
        category: "cremé",
    },
    {
        name: "1044",
        src: ColorGel1044,
        category: "cremé",
    },
    {
        name: "1045",
        src: ColorGel1045,
        category: "cremé",
    },
    {
        name: "1046",
        src: ColorGel1046,
        category: "cremé",
    },
    {
        name: "1047",
        src: ColorGel1047,
        category: "cremé",
    },
    {
        name: "1048",
        src: ColorGel1048,
        category: "cremé",
    },
    {
        name: "1049",
        src: ColorGel1049,
        category: "cremé",
    },
    {
        name: "1050",
        src: ColorGel1050,
        category: "cremé",
    },
    {
        name: "1051",
        src: ColorGel1051,
        category: "cremé",
    },
    {
        name: "1052",
        src: ColorGel1052,
        category: "shimmer",
    },
    {
        name: "1053",
        src: ColorGel1053,
        category: "cremé",
    },
    {
        name: "1054",
        src: ColorGel1054,
        category: "cremé",
    },
    {
        name: "1055",
        src: ColorGel1055,
        category: "cremé",
    },
    {
        name: "1056",
        src: ColorGel1056,
        category: "cremé",
    },
    {
        name: "1057",
        src: ColorGel1057,
        category: "cremé",
    },
    {
        name: "1058",
        src: ColorGel1058,
        category: "cremé",
    },
    {
        name: "1059",
        src: ColorGel1059,
        category: "cremé",
    },
    {
        name: "1060",
        src: ColorGel1060,
        category: "cremé",
    },
    {
        name: "1061",
        src: ColorGel1061,
        category: "cremé",
    },
    {
        name: "1062",
        src: ColorGel1062,
        category: "cremé",
    },
    {
        name: "1063",
        src: ColorGel1063,
        category: "shimmer",
    },
    {
        name: "1064",
        src: ColorGel1064,
        category: "shimmer",
    },
    {
        name: "1065",
        src: ColorGel1065,
        category: "cremé",
    },
    {
        name: "1066",
        src: ColorGel1066,
        category: "cremé",
    },
    {
        name: "1067",
        src: ColorGel1067,
        category: "cremé",
    },
    {
        name: "1068",
        src: ColorGel1068,
        category: "cremé",
    },
    {
        name: "1069",
        src: ColorGel1069,
        category: "cremé",
    },
    {
        name: "1070",
        src: ColorGel1070,
        category: "cremé",
    },
    {
        name: "1071",
        src: ColorGel1071,
        category: "cremé",
    },
    {
        name: "1072",
        src: ColorGel1072,
        category: "cremé",
    },
    {
        name: "1073",
        src: ColorGel1073,
        category: "cremé",
    },
    {
        name: "1074",
        src: ColorGel1074,
        category: "cremé",
    },
    {
        name: "1075",
        src: ColorGel1075,
        category: "cremé",
    },
    {
        name: "1076",
        src: ColorGel1076,
        category: "cremé",
    },
    {
        name: "1077",
        src: ColorGel1077,
        category: "cremé",
    },
    {
        name: "1078",
        src: ColorGel1078,
        category: "cremé",
    },
    {
        name: "1079",
        src: ColorGel1079,
        category: "cremé",
    },
    {
        name: "1080",
        src: ColorGel1080,
        category: "cremé",
    },
    {
        name: "1081",
        src: ColorGel1081,
        category: "metallic",
    },
    {
        name: "1082",
        src: ColorGel1082,
        category: "cremé",
    },
    {
        name: "1083",
        src: ColorGel1083,
        category: "metallic",
    },
    {
        name: "1084",
        src: ColorGel1084,
        category: "cremé",
    },
    {
        name: "1085",
        src: ColorGel1085,
        category: "cremé",
    },
    {
        name: "1086",
        src: ColorGel1086,
        category: "shimmer",
    },
    {
        name: "1087",
        src: ColorGel1087,
        category: "cremé",
    },
    {
        name: "1088",
        src: ColorGel1088,
        category: "cremé",
    },
    {
        name: "1089",
        src: ColorGel1089,
        category: "cremé",
    },
    {
        name: "1090",
        src: ColorGel1090,
        category: "metallic",
    },
    {
        name: "1091",
        src: ColorGel1091,
        category: "cremé",
    },
    {
        name: "1092",
        src: ColorGel1092,
        category: "cremé",
    },
    {
        name: "1093",
        src: ColorGel1093,
        category: "cremé",
    },
    {
        name: "1094",
        src: ColorGel1094,
        category: "cremé",
    },
    {
        name: "1095",
        src: ColorGel1095,
        category: "cremé",
    },
    {
        name: "1096",
        src: ColorGel1096,
        category: "cremé",
    },
];

const home = (props) => {
    return (
        <div className="container-fluid pt-3 pb-5 px-0">
            <div className="row mx-0 mt-4">
                <div className="col-12 jumbotron header-gel-polish">
                    <div className="col-sm-12 text-center p-0">
                        <div className="row py-5">
                            <div className="col-sm-12">
                                <div className="col-sm-6 mx-auto">
                                    <img src={Logo} className="col-sm-12" alt="logo"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row pb-5 mx-0">
                <div className="col-sm-12">
                    <h2>GEL COUTURE GEL POLISH</h2>
                    <div className="col-sm-8 mx-auto pt-3">
                        <p>Gel Couture Gel Polish can be applied as a semi permanent coating for the natural nails or as
                            a durable colour coat over acrylic and gel enhancements. Following a simple application and
                            removal protocol will ensure up to four weeks of chip-free wear without any damage to the
                            natural nails.</p>
                        <p>We formulated our Gel Polish to be solvent-free in order to eliminate the thickening or
                            hardening of the product over time.</p>
                        <p>No more worries about pulling, shrinking or dripping – only smooth, creamy self-leveling
                            consistency for complete product control and easy application.</p>
                        <p>The highest quality pure colour pigments are used in the formulation to ensure a solid and
                            streak-free coverage in two thin coats. The colour remains bold and vibrant and does not
                            change or fade with prolonged wear.</p>
                        <p>High-grade matte black opaque bottle coating blocks UV light to protect your gel polish from
                            in-bottle polymerisation.
                            Carefully designed dome shaped brush makes an application around the cuticle area easier
                            than ever before.</p>
                        <p style={{color: "#a69080", fontSize: '1.2rem'}}>Gel Couture Brand offers only the best quality products and we support this claim
                            with a full money back guarantee - if you feel that the product did not match your
                            expectations or any of our quality claims, we will refund you in full, no questions
                            asked!</p>
                    </div>
                </div>
            </div>
            <div className="row text-center mb-5 mx-0">
                <div className="col-sm-3">
                    <div className="row mx-auto">
                        <div className="col-sm-11 m-auto">
                            <img src={GelPolishImg} className="col-sm-12 bottle-img" alt="Gel Polish Bottle"/>
                        </div>
                    </div>
                    <div className="row mx-auto pt-4">
                        <div className="col-sm-11 m-auto">
                            <h4 className="SculptingGelItemText">Gel Couture Gel Polish Colour Coat</h4>
                            <em>Thick and creamy consistency, solvent-free Gel Polish Colour Coat that does not fade
                                with prolonged wear, does not shrink and pull away from the free edge or cuticle area
                                during
                                application.
                                <br/><i style={{color: '#a69080', fontSize: '1.2rem'}}>14ml - R179.00<br/>7ml - R129.00</i></em>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="row mx-auto">
                        <div className="col-sm-11 m-auto">
                            <img src={RubberBaseImg} className="col-sm-12 bottle-img" alt="Rubber Base Coat Bottle"/>
                        </div>
                    </div>
                    <div className="row mx-auto pt-4">
                        <div className="col-sm-11 m-auto">
                            <h4 className="SculptingGelItemText">Gel Couture Rubber Base Coat</h4>
                            <em>Flexible, low odour, extended wear, strong adhesion Rubber Base Coat. Suitable for use
                                with Gel Couture Gel Polish as well as Gel Couture Sculpting Gels. Application does not
                                require the use of a primer, being gentle and preserving the health of the natural nail.
                                <br/><i style={{color: '#a69080', fontSize: '1.2rem'}}>14ml - R179.00<br/>7ml - R129.00</i></em>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="row mx-auto">
                        <div className="col-sm-11 m-auto">
                            <img src={DiamondTopCoatImg} className="col-sm-12 bottle-img"
                                 alt='Diamond Top Coat Bottle'/>
                        </div>
                    </div>
                    <div className="row mx-auto pt-4">
                        <div className="col-sm-11 m-auto">
                            <h4 className="SculptingGelItemText">Gel Couture Diamond Top Coat</h4>
                            <em>Prolonged wear top coat that delivers brilliant shine for up to 4 weeks. Suitable for
                                use with Gel Couture Gel Polish as well as Gel Couture Sculpting Gels. This top coat
                                is tack free and cures without leaving an inhibition layer.
                                <br/><i style={{color: '#a69080', fontSize: '1.2rem'}}>14ml - R179.00<br/>7ml - R129.00</i></em>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="row mx-auto">
                        <div className="col-sm-11 m-auto">
                            <img src={VelvetTopCoatImg} className="col-sm-12 mx-auto bottle-img"
                                 alt='Velvet Top Coat Bottle'/>
                        </div>
                    </div>
                    <div className="row mx-auto pt-4">
                        <div className="col-sm-11 m-auto">
                            <h4 className="SculptingGelItemText">Gel Couture Velvet Top Coat</h4>
                            <em>Special effect matte tack free Top Coat that cures to a velvety tactile finish, without
                                leaving an inhibition layer.
                                <br/><i style={{color: '#a69080', fontSize: '1.2rem'}}>14ml - R179.00<br/>7ml - R129.00</i></em>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-5 pb-5 mx-0">
                <div className="col-sm-12 text-center">
                    <h2>GEL POLISH COLOUR CHART</h2>
                </div>
            </div>
            <div className="row mx-0 text-center">
                <a href={colorChartPDF} download className="col-sm-8 btn btn-primary btn-gel-couture mx-auto py-4">Download
                    the Full Colour Chart (2.2Mb)</a>
            </div>
            <div className="row mt-5 mx-auto">
                {
                    colorChart.map((item, index) => {
                        return (
                            <div className="col-md-2 my-2" key={item.name}>
                                <div className="row">
                                    <div className="m-auto">
                                        <img src={item.src} className="colorChartColor col-8" alt="color circle"/>
                                    </div>
                                </div>
                                <div className="row mx-auto pt-2 mt-2">
                                    <em className="col-sm-12">#{item.name}</em>
                                    <em className="col-sm-12">{item.category}</em>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <div className="row mx-0">
                <div className="col-sm-12 mx-auto">
                    <ToTopButton/>
                </div>
            </div>
        </div>
    )
};

export default home;