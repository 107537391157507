import React, {Component} from "react";
import $ from 'jquery';

class GoToTopButton extends Component{
    constructor(){
        super();
        this.onclick = this.onclick.bind(this);
    }

    render() {
        return <nav className="navbar fixed-bottom bottom-nav-totop">
            <button onClick={this.onclick} className="mx-auto btn" style={{backgroundColor: 'transparent'}}>
                <i className="fad fa-chevron-up" style={{color: "#4a1818"}}/>
            </button>
        </nav>
    }

    onclick() {
        $("html, body").animate({scrollTop: 0}, 1500);
    }

}

export default GoToTopButton;