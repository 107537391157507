import React, { Component } from 'react';
import './App.css';

import { BrowserRouter, Route } from "react-router-dom";

import Navbar from './components/Navbar/Navbar';

import Home from './pages/Home';
import SculptingGel from './pages/products/SculptingGel';
import GelPolish from './pages/products/GelPolish';
import ContactUs from './pages/ContactUs';

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <div className="App container-fluid p-0" style={{padding: 0, paddingTop: "60px"}}>
                    <Navbar/>
                    <Route path="/" exact component={Home}/>
                    <Route path="/products/sculpting-gel" exact component={SculptingGel}/>
                    <Route path="/products/gel-polish" exact component={GelPolish}/>
                    <Route path="/contact-us" exact component={ContactUs}/>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
